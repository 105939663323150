.individual span.individualLabel {
  text-decoration: underline;
  font-weight: bold;
}

.individual span.grupoLabel {
  text-decoration: underline;
}

.grupo span.individualLabel {
  text-decoration: underline;
}

.grupo span.grupoLabel {
  text-decoration: underline;
  font-weight: bold;
}

span.preview-title {
  font-size: 40px;
}

@media (max-width: 1640px) {
  span.preview-title {
    font-size: 30px !important;
  }
}

@media (max-width: 1542px) {
  span.preview-title {
    font-size: 20px !important;
  }
}

@media (max-width: 1421px) {
  div.user-area {
    display: none !important;
  }
}

@media (max-width: 1130px) {
  span.preview-label {
    display: none !important;
  }
}

@media (max-width: 849px) {
  #logo {
    display: none !important;
  }
}