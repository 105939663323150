@media (max-width: 1199px) {
  .answerers-estilo-comunicacao-div-wrapper {
    flex-direction: column !important;
  }

  .answerers-estilo-comunicacao-col-wrapper {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }
}