.storytelling-line {
  padding-top: 1em;
  font-size: 12px;
  text-align: justify;

  &::first-letter {
    font-weight: 700;
  }
}

.storytelling-no-responses {
  padding-top: 1em;
  font-size: 12px;
  color: red;
}

h6 {
  font-size: 12px;
  font-weight: 700;
}
