/* The slider */
:global(.slider) {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

:global(.slider:before) {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: -3px;
  background-color: #10949d;
  transition: .4s;
}

:global(input:checked + .slider) {
  background-color: #54c1ca;
}

:global(input:focus + .slider) {
  box-shadow: 0 0 1px #669ca0 ;
}

:global(input:checked + .slider:before) {
  transform: translateX(15px);
}

/* Rounded sliders */
:global(.slider.round) {
  border-radius: 34px;
}

:global(.slider.round:before) {
  border-radius: 50%;
}

:global(.modal-margin) {
  margin: 10px 0 20px 0;
}