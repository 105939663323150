.input-wrapper-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px;
}
.input-wrapper-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-checkbox {
  position: relative;
  display: inline-block;
  top: 0;
  left: -25px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #c9c9c9;
}

/* On mouse-over, add a grey background color */
.input-wrapper-checkbox:hover input ~ .checkmark-checkbox {
  background-color: #ccc;
}

.checkmark-checkbox.small {
  height: 17px;
  width: 17px;
}

.checkmark-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.input-wrapper-checkbox input:checked ~ .checkmark-checkbox:after {
  display: block;
}

.input-wrapper-checkbox .checkmark-checkbox:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-wrapper-checkbox .checkmark-checkbox.small:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
}
