span.nome-cliente {
  font-size: 10px;
  color: #262626;
}

h2.nome-cliente {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  margin: 0;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  h2.nome-cliente {
    font-size: calc(14px + (16 - 14) * ((100vw - 1000px) / (1500 - 1000)));
  }
  span.nome-cliente {
    font-size: calc(10px + (12 - 10) * ((100vw - 1000px) / (1500 - 1000)));
  }
}

@media (min-width: 1500px) {
  h2.nome-cliente {
    font-size: 16px;
  }
  span.nome-cliente {
    font-size: 12px;
  }
}
