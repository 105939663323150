.erro,
.acerto {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0 5px;
}

.erro {
  background-color: #d44444;
}

.acerto {
  background-color: #5fba77;
}

.erro-legenda,
.acerto-legenda {
  margin: 0 10px 0 0;
}

.legend-row {
  padding-top: 5px;
  padding-bottom: 5px;
}