.form-selector {
  border-radius: 5;
  max-width: 70%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC')
    no-repeat scroll 0 0 transparent;
  background-color: #ffffff;
  background-position: right 1em top 50%;
  -moz-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 4px 2.5em 4px 20px;
}
