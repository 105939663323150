#inner-div {
  height: 100%;
  box-sizing: border-box;
  /* background-image: url('https://files-storage.s3.amazonaws.com/iapp-assets/iapp_login_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat; */
}

.botao {
  background-color: #8a9476;
  width: 100%;
}

.icons {
  background-color: #8a9476;
  border-radius: 4px;
  align-items: center;
  /* align-content: stretch; */
  width: 40px;
}

.iconsImg {
  margin-left: 10px;
  width: 16px;
}

.esqueciSenha {
  color: white !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.input-login::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
.input-login:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.input-login::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.input-login:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.input-login::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}