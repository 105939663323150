.modal-resultado-header h2 {
  font-weight: 700;
  font-size: 16px;
}
.modal-resultado-header h3 {
  font-size: 12px;
}

.modal-resultado-header > .col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.modal-resultado-header {
  margin: 5% 0 0 0;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .modal-resultado-header h2 {
    font-size: calc(16px + (20 - 16) * ((100vw - 1000px) / (1500 - 1000)));
  }
  .modal-resultado-header h3 {
    font-size: calc(12px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
  }
}
@media (min-width: 1500px) {
  .modal-resultado-header h2 {
    font-size: 20px;
  }
  .modal-resultado-header h3 {
    font-size: 14px;
  }
}
