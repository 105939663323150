#header-logged {
  background-color: #002a4b;
  padding: 0 20px 10px 20px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0;
}

#header-logged > #userdata {
  height: 50px;
  display: flex;
  align-items: flex-end;
}

#header-logged > #userdata > .usr > img {
  width: 110px;
  height: 110px;
  border: solid 2px white;
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
  border-radius: 60px;
  position: relative;
  top: 60px;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  margin: 0 10px 0 0;
}
#header-logged > #userdata > .usrName p {
  color: #ffffff;
  padding-top: 0;
  /* width: calc(100vw - 140px); */
  line-height: 18px;
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  margin: 0;
}

#header-logged > #logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#header-logged > #logo-wrapper > .lgt {
  margin-left: -80px;
  font-size: 16px;
  margin-top: 2px;
  color: white;
  text-decoration: underline;
  position: absolute;
  cursor: pointer;
}

#header-logged > .bloco > img {
  margin-top: 0px;
}

@media (max-width: 450px) {
  #header-logged {
    width: 110%;
  }

  #header-logged > #logo-wrapper > .lgt {
    margin-left: -50px;
  }
}


@media screen and (max-width: 566px) {
  #header-logged {
    justify-content: start;
  }
  #header-logged > #userdata {
    width: 190px;
  }

  #header-logged > #logo-wrapper {
    width: calc(100vw - 230px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  }

  #header-logged > #logo-wrapper > .lgt {
    position: absolute;
    left: 0;
  }

  #header-logged > #logo-wrapper > #logo {
    margin-top: 0px;
    width: 100px;
  }

  #header-logged > #userdata > .usrName > p {
    max-width: 300px;
  }

  #header-logged > #userdata > .usrName > p {
    color: #000000;
    padding-top: 0;
    width: calc(100vw - 140px);
    line-height: 18px;
    height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  #header-logged > #userdata > .usrName {
    position: relative;
    top: 55px;
  }
}
