html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	font-size: 100%;
}


body {
  font-size: 12px;
}

p {
  font-size: 12px;
}

hr {
  border-top: none;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cdd8dc;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cdd8dc;
}
input::-ms-input-placeholder {
  /* IE 10+ */
  color: #cdd8dc;
}
input::-moz-placeholder {
  /* Firefox 18- */
  color: #cdd8dc;
}
.row {
  min-width: 100%;
}

.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-wrap-visible {
  overflow: hidden;
  white-space: nowrap;
}

.one-line-text {
  overflow: hidden;
  margin-right: 2em;
  width: min-content;
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
}

.row.without-margin {
  margin: 0;
}

.row-centered {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.without-padding {
  padding: 0 !important;
}

#no-padding-right {
  padding-right: 0 !important;
}

.no-break {
  display: flex;
  flex-wrap: nowrap;
}

.row.no-break {
  flex-wrap: nowrap;
}

.scroll {
  overflow: auto;
}

.full-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiInputBase-input {
  font-size: 12px;
}
@media (min-width: 1000px) and (max-width: 1500px) {
  .MuiInputBase-input {
    font-size: calc(12px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
  }
}

@media (min-width: 1500px) {
  .MuiInputBase-input {
    font-size: 14px;
  }
}

.without-position {
  position: static !important;
}

.min-width {
  min-width: min-content;
}

#color-to-blue {
  filter: invert(30%) grayscale(100%) brightness(0%) contrast(4);
}

.instrument-container {
  padding: 5vh 2vw;
}

*::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar {
	width: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #707070;
}

.tippy-popper {
  max-width: 1000px !important;
}

.remove-btn-focus-outline button:focus {
  outline: none;
  box-shadow: none;
}

button:focus {
  outline: none !important;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  font-size: 21px !important;
  color: #686868 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.alice-carousel__prev-btn-item:hover {
  opacity: .4;
}

.alice-carousel__next-btn-item:hover {
  opacity: .4;
}