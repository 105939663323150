.titulo-input {
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  margin: 0;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .titulo-input {
    font-size: 12px;
  }
}

@media (min-width: 1500px) {
  .titulo-input {
    font-size: 12px;
  }
}
