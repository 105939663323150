.campo-nome {
  display: flex;
}

.campo-nome > .row {
  width: 22%;
  border-bottom: 1px solid #c9c9c9;
}

.campo-nome > .row > input {
  border: none;

  background-color: transparent;
  font-size: 12px;
  margin-left: 30px;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .campo-nome > .row > input {
    font-size: 12px;
  }
}

@media (min-width: 1500px) {
  .campo-nome > .row > input {
    font-size: 12px;
  }
}
