.toolTips {
  margin-right: 0;
  margin-left: 0;
  height: 75px;
}

.toolTips2 {
  margin-right: 0;
  margin-left: 0;
  height: 55px;
}

.toolTips p {
  margin-right: 5px;
}

.btnSalvar {
  justify-content: center;
}

.btnSalvar button {
  padding: 20px 35px;
}

.itensAlign .row {
  align-items: center;
}

.selectForm {
  margin-left: 5px;
}

.selectForm2 {
  max-width: 90% !important;
  padding: 10px 2.5em 10px 20px !important;
}

.modal-content {
  padding: 0 10px;
}

/* Hide the browser's default radio button */
.radioCampo input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  left: 0;
  height: 15px;
  width: 15px;
  top: 4px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioCampo:hover input[type="radio"] ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioCampo input[type="radio"]:checked ~ .checkmark {
  background-color: white;
  border:1px solid #00264B;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioCampo input[type="radio"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
input[type="radio"] {
  top: -1px;
  left: -1px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 100%;
  background: #00264b;
}

.custom-radio {
  display: flex;
  align-items: center;
}

.custom-radio label {
  margin-bottom: 0;
  margin-left: 10px;
}


.wrapper-assessment-text {
  background-color: #fff;
  border: 1px solid #c9c9c9;
  height: 250px;
  max-height: 250px;
  width: 100%;
}

.editor-assessment-text {
  height: 80%;
}

.public-DraftEditor-content {
  height: 94%;
}
