.sidenav {
  height: calc(100% - 55px);
  width: 250px; 
  position: fixed; 
  z-index: 10; 
  top: 55px; 
  right: -250px;
  background-color: #01294B;
  transition: 0.5s;
  overflow-y: scroll;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.widgets-aside-open {
  background-color: #01294B;
  color: #fff;
  border: 0;
  position: fixed;
  top: 120px;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 10px 0 0 10px;
  z-index: 20;
  transition: 0.5s;

  &:focus {
    outline: none;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}