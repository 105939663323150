.row.folder {
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
}
.open-folder > div {
  margin: 0 0 0 20px;
}

.folder-collapse {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.folder-collapse:hover {
  padding: 5px;
  background-color: #ececec;
}
